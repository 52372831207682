var ismobile = '23';
var $window;
var $header;
var $teaser;
var $navbar;
var fadeableContent;
var fadeableFooter;
var $teasertext;
var $teasertextContainer;
var menueMaxScrollHoehe = 110;
var teaserSliderScrollFaktor = 2.5;

var refchange = {};
var refChangeTimer;

var $embedResponsive;

var $logo;
var $realLogo;
var $logoHeight;
var $viewportFaktor;

var $mainMenueLi;

var $banner;
var viewport = ResponsiveBootstrapToolkit;

var intersectionObserver;

$(document).ready(function () {
    var bootstrap4Divs = {
        'xs': $('<div class="d-block d-sm-none"></div>'),
        'sm': $('<div class="d-none d-sm-block d-md-none"></div>'),
        'md': $('<div class="d-none d-md-block d-lg-none"></div>'),
        'lg': $('<div class="d-none d-lg-block d-xl-none"></div>'),
        'xl': $('<div class="d-none d-xl-block"></div>')
    };
    // viewport.use('bootstrap4', bootstrap4Divs);

    $window = $(window);
    $header = $('header');
    $teaser = $('.main__teaser__tease');
    $logo = $header.find('.logo-container');
    $realLogo = $logo.find('img');
    $viewportFaktor = window.innerHeight;
    $logoHeight = $viewportFaktor / 8;
    $embedResponsive = $teaser.find('.embed-responsive');
    $banner = $('.banner');
    $teasertextContainer = $('.teaser-text--container');

    $navbar = $header.find('.navbar-selector');
    $teasertext = $teaser.find('.teaser-text');

    if($navbar.hasClass('navfixedtop') === true) {
        $('[data-load]').removeData('load');
        $('[data-load]').each(function() {
            $(this).removeAttr('data-load');
        });
    }

    initOnepagerCallback();

    windowResize();
    $window.on('resize', function () {
        windowResize();
        if(!ismobile) {
            // initTeaserSize();
            // setLogoTop();
        }
        // setContentStartOffset();
    });
    $window.on('scroll', function (e) {
        if (!ismobile) animateMenue(e);
        fadeContent();
    });

    initContent();

    $window.trigger('scroll');
});

function refChange(a) {
    var aktiv = a.children('.ref-slides').find('.active:first');
    if(a.is(':hover')) {
        if (aktiv.next(".ref-hide").length) {
            aktiv.addClass('ref-hide').removeClass('active');
            aktiv.next(".ref-hide").addClass('active').removeClass('ref-hide');
        } else {
            aktiv.parent().find('.ref-hide:first-child').removeClass('ref-hide').addClass('active');
            aktiv.addClass('ref-hide').removeClass('active');
        }


        refChangeTimer = setTimeout(function () {
            refChange(a);
        }, 1500);
    } else {
        clearTimeout(refChangeTimer);
    }
}

function initOnepagerCallback() {
    if(typeof onepager == 'undefined') {
        setTimeout(function() {
            initOnepagerCallback();
        }, 50);
    } else {
        onepager.callback = function () {
            initContent();
        };
    }
}

function initContent() {
    if(window.IntersectionObserver) {
        intersectionObserver = new IntersectionObserver(function(event) {
            event.forEach(function(entry) {
                if(entry.isIntersecting === true) {
                    entry.target.classList.add('fadeContentIn');
                    intersectionObserver.unobserve(entry.target);
                }
            });
        });
    }

    initFadeTeaser();
    // initTeaserSize();
    initFadeContent();
    initFlexsliderTeaser();

    initScrollTop();

    var bgvideo = $('#bgvideo').parent();
    var bgvideosrc = $('#bgvideo');
    if(ismobile) {
        initMobileFunctions();
        console.log("videosd");
        bgvideosrc.attr('src', bgvideosrc.data('videosd'));

        $('.nav-link').on('click', function() {
            $('.navbar-toggler').trigger('click');
        });
    } else {
        console.log("videohq");
        bgvideosrc.attr('src', bgvideosrc.data('vidoehq'));
        bgvideo.get(0).load();
        bgvideo.attr('loop', 'true');
        bgvideo.attr('autoplay', 'true');
    }

    if(!intersectionObserver) {
        $window.off('scroll').on('scroll', function (e) {
            fadeContent();
        });
    }
    fadeContent();
    if(ismobile == false) {
        $('.bild').mouseenter(function () {
            var a = $(this);
            if (a.parent().hasClass('referenz')) {
                clearTimeout(refChangeTimer);
                refChange(a);
            }
        });
    } else {
        $('.ref-hide').hide();
    }
}

function lazyLoad() {
    fitNavspaltenHeaders();
    $(window).trigger('scroll');
}

function initFadeContent() {
    fadeableContent = $('.main__content__ajax').find('>.container, >.container-fluid').find('>*').not('.no-fade');
    fadeableFooter = $('#footer').find('>div').not('.no-fade');

    if(!intersectionObserver) {
        $.each(fadeableContent, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });

        $.each(fadeableFooter, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });
    } else {
        $.each(fadeableContent, function(i, v) {
            intersectionObserver.observe(v);
        });
        $.each(fadeableFooter, function(i, v) {
            intersectionObserver.observe(v);
        });
    }
}

function fadeContent() {
    var offset = $window.scrollTop() + $window.height() + 200;
    $.each(fadeableContent, function (i, v) {
        if ($(v).data().top < offset) {
            $(v).addClass('fadeContentIn');
        }
    });
    if (fadeableFooter.data().top < offset) {
        fadeableFooter.addClass('fadeContentIn');
    }
}

function windowResize() {
    if(viewport.current() == 'xs' || viewport.current() == 'sm') {
        ismobile = true;
    } else {
        ismobile = false;
    }
}

function animateMenue(e) {
    if (ismobile) return;
    var scrollhoehe = e.currentTarget.scrollY;

    var headerOffset = scrollhoehe / 20;

    var m = scrollhoehe / 6;
    var bannerHeight = scrollhoehe / 3;
    if(bannerHeight > 500) bannerHeight = 500;
    // var scale = 1*(scrollhoehe/30);
    // if(scale < 1) scale = 1;
    // if(scale > 10) scale = 10;

    if(headerOffset > menueMaxScrollHoehe) headerOffset = menueMaxScrollHoehe;
    // $header.css('transform','translateY(-'+headerOffset+'px)');
    // $logo.css('transform', 'translateY('+headerOffset+'px)');


    var logoHeight = $logoHeight - headerOffset;

    var balkenleft = headerOffset * 5;

    balkenleft = balkenleft + 100;

    // if(balkenleft > 145) balkenleft = 145;

    $('.balken-grau').css('left', '-'+balkenleft+'vw');

    if(logoHeight < 6) logoHeight = 6;
    // if(logoHeight > $logoHeight) logoHeight = $logoHeight;


    logoHeight = logoHeight / ($viewportFaktor / 100);

    if(window.innerHeight >= 1080)
        logoHeight = logoHeight / ((1080 / 8) / 100);

    if(logoHeight < 6) logoHeight = 6;

    $logo.css('height', logoHeight + 'vw').css('width', logoHeight+'vw');
    // $realLogo.css('height', logoHeight + 'px');

    var navbarTranslateTop = scrollhoehe * 2;
    if(navbarTranslateTop > 130) navbarTranslateTop = 130;

    if($navbar.hasClass('navfixedtop') === false)
    $navbar.css('transform', 'translateY(-'+navbarTranslateTop+'px)');

    var deckkraft = scrollhoehe / 100 * 0.3;

    if(deckkraft > 1) deckkraft = 1;
    var deckkraft2 = deckkraft;

    if(deckkraft2 > 0.75) deckkraft2 = 0.75;

    if($navbar.hasClass('navfixedtop') === false)
        $navbar.css('background', 'rgba(240, 240, 240, '+deckkraft+')').css('box-shadow', '0px 0px 5px rgba(0,0,0,'+deckkraft2+')');

    if($navbar.hasClass('navfixedtop') === false)
    $('.nav-item').each(function(index) {
        if($(this).hasClass('active') != true) {
            // $(this).children().css('cssText', 'color: #ff0000 !important;');
            setColor($(this).children(), {r: 255,   g: 255, b: 255}, {r: 26, g: 62, b: 112}, deckkraft);
            setColorShadow($(this).children(), {r:  5,   g:  23, b:  49}, {r: 255, g: 255, b: 255}, deckkraft);
        } else {
            setColorShadow($(this).children(), {r:  5,   g:  23, b:  49}, {r: 255, g: 255, b: 255}, deckkraft);
        }
    });
    // setColor($('.nav ul .active a'), 'color', {r: 100, g: 200, b: 55}, {r: 0,   g: 100, b: 100}, (deckkraft * 100));

    if($teaser.hasClass('hideTeaser') === false) {
        m = m * teaserSliderScrollFaktor;
        $teaser.css('transform','translateY('+m+'px)');
        $teaser.find('.flex-control-nav').css('transform','translateY(-'+m+'px)');
        $teasertextContainer.css('transform', 'translateX(-50%) translateY(-'+m+'px)');
        // $banner.css('transform','scaleY('+scale+')');
        $banner.css('padding-top',(100+bannerHeight)+'px');
    }
}

//TIP: for performance Arrays are better to use than objects as here.

function setColor(element, color1, color2, fraction) {

    var r = color1.r + (color2.r - color1.r) * fraction;
    var g = color1.g + (color2.g - color1.g) * fraction;
    var b = color1.b + (color2.b - color1.b) * fraction;

    element.css('color', 'rgb(' + (r | 0) + ',' + (g | 0) + ',' + (b | 0) + ')');
}

function setColorShadow(element, color1, color2, fraction) {

    var r = color1.r + (color2.r - color1.r) * fraction;
    var g = color1.g + (color2.g - color1.g) * fraction;
    var b = color1.b + (color2.b - color1.b) * fraction;

    element.css('text-shadow', '-0.5px -0.5px 0 rgba(' + (r | 0) + ',' + (g | 0) + ',' + (b | 0) + ', 0.5)');
}

function initKontaktformular() {
    var $frmContact = $('#frmContact');
    var $submitBtn = $('#submitBtn');
    $frmContact.submit(function () {
        var l = Ladda.create(document.querySelector('#submitBtn'));
        l.start();
        $.ajax({
            type: 'POST',
            url: window.location.href,
            data: $frmContact.serialize(),
        }).done().then(function (msg) {
            l.stop();
            msg = JSON.parse(msg);
            var tmp = $submitBtn.parent().html();
            $submitBtn.parent().html('<div class="alert alert-' + msg.status + ' text-left fade in">' + msg.text + '</div>');
            if (msg.status === 'success')
                $frmContact[0].reset();
            setTimeout(function () {
                $('.alert').parent().fadeOut(300, function () {
                    $('.alert').parent().html(tmp);
                    $submitBtn.parent().fadeIn(300);
                });
            }, 1500);
        });

        return false;
    });
}

function initMobileFunctions() {
    $('.mobilemenu__button').off('click').on('click', function () {
        $('.mobilemenu').addClass('open');
    });
    $('.mobilemenu__button-close, .mobilemenu').off('click').on('click', function () {
        $('.mobilemenu').removeClass('open');
        $('.mobilemenu__button-close').css('position', 'absolute');
    });
    $('.mobilemenu').on('transitionend', function (e) {
        if(parseInt(getComputedStyle(e.target).right) == 0) {
            $(e.target).find('.mobilemenu__button-close').css('position', 'fixed');
        } else {
            $('.mobilemenu').scrollTop(0);
        }
    });
}

function initFadeTeaser() {
    $teasertext.addClass('show');
    $teasertext.find('span').addClass('show');
}

function fitNavspaltenHeaders() {
    $('.navspalten--listing').each(function(i, row) {
        var maxHeight = 0;
        var $navspalten = $(row).find('.navspalten').find('.navspalten--headline');
        $navspalten.each(function(x, ele) {
            var height = parseInt($(ele).css('height'));
            if(maxHeight < height) maxHeight = height;
        });
        $navspalten.css('height',maxHeight+'px');
    });
}

function initTeaserSize() {
    var h2Offset = 130;
    var height = $window.height() - h2Offset;
    if($teaser.hasClass('main__teaser__tease')) {
        $teaser.css('height', height+'px');
    }
}

function initScrollTop() {
    $('.scrollTop').off('click').on('click', function() {
        $window.scrollTop(0);
    });
}

var disabledLink = function(e) {
    e.preventDefault();
    e.stopPropagation();
}

function addArticle(obj) {

    $.ajax({
        type: 'POST',
        url: obj.data('url'),
        data: obj.parent().parent().serialize(),
    }).done().then(function (msg) {
        var a = $('.shoppingcart--items').html();
        a++;
        $('.shoppingcart--items').html(a);
        showArticleAddWindow();
    });
}

function showArticleAddWindow() {
    $('body').append('<div class="modal fade" id="addedToCart" tabindex="-1" role="dialog">\n' +
        '  <div class="modal-dialog" role="document">\n' +
        '    <div class="modal-content">\n' +
        '      <div class="modal-header">\n' +
        '        <h3 class="modal-title">Hmmm... Lecker!</h3>\n' +
        '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
        '          <span aria-hidden="true">&times;</span>\n' +
        '        </button>\n' +
        '      </div>\n' +
        '      <div class="modal-body">\n' +
        '        Der Artikel wurde zum Warenkorb hinzugefügt.\n' +
        '      </div>\n' +
        '      <div class="modal-footer">\n' +
        '        <button type="button" class="btn btn-primary" id="weitershoppen">weiter shoppen</button>\n' +
        '        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="zumwarenkorb">zum Warenkorb</button>\n' +
        '      </div>\n' +
        '    </div>\n' +
        '  </div>\n' +
        '</div>');

    $('#addedToCart').modal('toggle');

    $('#weitershoppen').off('click').on('click', function() {
       $('#addedToCart').modal('hide');
    });

    $('#zumwarenkorb').off('click').on('click', function() {
        $('.fa-shopping-bag').parent().trigger('click');
    });
}